import { AMOUNT, BOOLEAN } from '@/modules/questionnaire/api/constants';

export const YES_RESPONSE_BINDINGS = {
  value: BOOLEAN.YES,
  text: 'label.yes'
};

export const NO_RESPONSE_BINDINGS = {
  value: BOOLEAN.NO,
  text: 'label.no'
};

export const YES_NO_RESPONSE_OPTIONS = [{ ...YES_RESPONSE_BINDINGS }, { ...NO_RESPONSE_BINDINGS }];

export const AMOUNT_OPTIONS = [
  { value: AMOUNT.CONSIDERABLY, text: 'amount.considerably' },
  { value: AMOUNT.MODERATELY, text: 'amount.moderately' },
  { value: AMOUNT.NO, text: 'amount.no' }
];
